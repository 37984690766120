
import React from 'react';
import { Card } from 'primereact/card';

import "./cards.css"
import api from '../api';

import {
    Link
} from 'react-router-dom';

const Cards = () => {

	const [data,setData] = React.useState([]);
    const getData = ()=>{
        api.post("data.php",{get:"get"}).then(e=>setData(e.data)).catch(e=>console.log(e));
    }
    React.useEffect(getData,[])
    return (
        <div id="areaofuse" className="container">     
            <h1>WHERE CAN BE USED</h1>  
            <h2 className={'subtitle'}>Applications of glue</h2>
            <div className={"containers2"}>
            {
                data.map((v,i)=>{
                    let title = v.texts
                    title = title.split("_");
                    title = title.join(' ');
                    return <Link className={"url"} to={"/data/"+v.texts}>
                            <Card key={i} className={"cards"}>
                                <img className={"image"} alt={title} src={"uses/"+v.id+".png"} />
                                <h3 className={"title"}>{title}</h3>
                            </Card>
                    </Link>
                })
            }
            </div>

        </div>
    );
}
    
export default Cards;