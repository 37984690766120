
import React from 'react';
import { Menubar } from 'primereact/menubar';
import {Link} from "react-router-dom"
import "./navbar.css"

const Navbar = () => {
    const items = [
        {
            label:"About the product",
            items:[
                {
                    label:"Areas of use",
                    url:"/#areaofuse"
                },
                {
                    label:"Instructions",
                    url:"/instruction"
                }
            ]
        },
        {
            label:"About company",
            url:"/company",
        },
        {
            label:"Indications for use",
            url:"/indications"
        },
        {
            label:"Reviews"
        },
        {
            label:"Contacts"
        }
    ];

    const start = <Link to='/'><img alt="logo" src="/logo.svg" height="40" className="p-mr-2"></img></Link>;
    const end = <div className="phone">
        <h2>
            <i className="pi pi-phone"></i>000 000 000 00
        </h2>
    </div>;

    return (
        <div>
            <div className="cardss">
                <div className="left">
                    {
                        start
                    }
                </div>
                <div className="right">
                {
                    end
                }
                <Menubar  model={items} />
                </div>
            </div>
        </div>
    );
}
    
export default Navbar;