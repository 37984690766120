
import React from 'react';
import CardVertical from '../component/cardsvertical';
import './style.css'
import { BreadCrumb } from 'primereact/breadcrumb';

const Indications = (props) => {
    const home = { icon: 'pi pi-home', url: '/' }
    const items = [
        { label: "Indications For Use" },
    ];
    
    return(
        <div className={"containerss"}>
            <div className={"lefts"}>
                <CardVertical/>
            </div>
            <div className={"rights"}>
                <BreadCrumb model={items} home={home}/>
                <div class="content">
                    <h1>Indications for use</h1>
                    <p>
                        <b>
                            "Sulfacrylate": application features and indications </b>
                    </p>
                    <p>
                        Medical adhesive composition "Sulfacrylate" is actively used in surgery and other branches of medicine, due to
                        its high efficiency, excellent hemostatic and bactericidal properties. The wide application of medical glue is
                        also due to its ability to rapidly polymerize and resorb in the body.
                    </p>
                    <p>
                        <b> So, "Sulfacrylate" is used "with: </b>
                    </p>
                    <ul>
                        <li>
                            Various surgical interventions in the abdominal cavity, obstetrics and gynecology, thoracic surgery,
                            neurosurgery, traumatology, cardiovascular surgery, dentistry, etc.; </li>
                        <li>
                            Operative interventions in veterinary medicine. </li>
                        <li>
                            This is by no means a complete list, as glue does not have any limitations on the application in other areas
                            of medicine. </li>
                    </ul>
                    <h2>
                        Features of the use of "Sulfacrylate" </h2>
                    <p>
                        Medical glue can be used without additional preparation and is a fluid whose fluidity is higher than that of
                        water. The adhesive is packed in sterile syringes with a volume of 1.0 - 2.0 ml or sterile tubes with a volume
                        of 0.5 - 2.0 ml.
                    </p>
                    <p>
                        After application, the adhesive remaining in the syringe / tube can be reused - to do this, the syringe / tube
                        is closed with the plug / back of the lid and placed in the refrigerator.
                    </p>
                    <ul>
                        <li>
                            The consumption of glue is quite economical and is about 0.03 ml (one drop) per 1 cm<sup>2 </sup>of the
                            treated surface;<sup> </sup></li>
                        <li>
                            That the formed glutinous film faster is polymerized its surface it is possible to moisten with sterile fiz.
                            solution or solution of furicillin; </li>
                        <li>
                            Before using Sulfacrylate, the wound surface should be soaked with a gauze or swab; </li>
                        <li>
                            If an excess amount of glue has been applied to the wound surface, the polymerization rate will
                            significantly decrease. Also, a "hard scab" of glue is formed on the surface, and this reduces the
                            effectiveness of its application; </li>
                        <li>
                            The glue is applied through a dry needle from an ampoule or syringe in a thin layer, forming a thin elastic
                            film. The polymerization itself lasts for 10-120 seconds. </li>
                    </ul>
                    <p style={{textAlign:"center"}}>
                        <b>
                            Before applying the medical glue "Sulfacrylate", we recommend that you carefully read the instructions for
                            use in the package. </b>
                    </p>
                </div>
            </div>
        </div>
    )
}
    
export default Indications;