
import React from 'react';
import CardVertical from '../component/cardsvertical';
import './style.css'
import { BreadCrumb } from 'primereact/breadcrumb';
import styles from './instruction.module.css'

const Instruction = (props) => {
    const home = { icon: 'pi pi-home', url: '/' }
    const items = [
        { label: "About Product" },
        { label: "Instruction" },
    ];
    
    return(
        <div className={"containerss"}>
            <div className={"lefts"}>
                <CardVertical/>
            </div>
            <div className={"rights"}>
                <BreadCrumb model={items} home={home}/>
                <div className ='content'>
                    <h1>Instruction</h1>
                    <p style={{textAlign: "left"}}>
                        The medical adhesive composition "Sulfacrylate" has the features of application - that is why we offer you the
                        instruction for acquaintance.
                    </p>
                    <p style={{textAlign: "left"}}>
                        If you have not found an answer to your question on our website and, in particular, in the application
                        instructions for medical glue, our specialists will be happy to advise you on our phone number <b> 000 00 000 000  . The call is free. </b>
                    </p>
                    <p>
                    </p>
                    <p>
                        <b><a className={styles.custom_a} href="/uses/Sulfacrylate_instruction.pdf" target="_blank"> Download the instruction </a> </b>
                    </p>
                    <h2> Instructions for the use of medical glue "Sulfacrylate" </h2>
                    <p style={{textAlign: "left"}}>
                        The adhesive composition "Sulfacrylate" is produced packed in disposable syringes in a volume of 1 ml. The kit
                        includes 1 sterile injectable needle.
                    </p>
                    <p style={{textAlign: "left"}}>
                        The outer surface of the syringe is sterile! Before use, the syringe with glue and the needle are removed from
                        the individual packages. The needle is put on the syringe, which is then transferred to the operating table. Do
                        not use without needles! The effects of gamma rays and ultrasound are not allowed.
                    </p>
                    <p style={{textAlign: "left"}}>
                        Depending on the purpose pursued during the operation using glue, it is possible to achieve various effects:
                        perform haemostasis with moderate parenchymal hemorrhage, strengthen the seam line of the created anastomosis,
                        create a hermitism in the damaged zone, perform organ fixation, create a pseudocapsule, perform peritonization
                        of the organ, create an organ-anastomosis , sealed fistula, etc.
                    </p>
                    <p style={{textAlign: "left"}}>
                        Before applying the glue, the wound surface is drained with a gauze cloth, in the presence of large bleeding
                        vessels, their ligation is performed.
                    </p>
                    <p>
                        <b>
                            Adhesive composition is applied to the wound surface by drop. </b>
                    </p>
                    <p style={{textAlign: "left"}}>
                        <img width="230" alt="6.jpg" src="/uses/sol.jpg" height="164"
                            title="6.jpg" align="left"/> The surgeon squeezes out the glue on drop, creating the conditions for its
                        uniform spreading over the surface in the necessary zone at the rate of 1 drop per 1 sq. km. cm area. With this
                        application, the glue well covers the wound surface with a thin layer and quickly polymerizes, forming a thin
                        elastic film. The glue is very fluid. The polymerization lasts for 10-120 seconds. On the surface an elastic
                        film is formed, which ensures the hermetic properties of the compounds of the surfaces to be glued together or
                        the hemostasis of the wound surface.
                    </p>
                    <p style={{textAlign: "left"}}>
                        When the glue is applied in large quantities, the latter is polymerized for a longer time, while a "hard" corpse
                        of the polymerized glue is formed on the wound surface. Excess of applied glue reduces the effect of its use.
                        For faster polymerization, the surface of the adhesive film can be moistened with physiological saline or a
                        solution of furacillin.
                    </p>
                    <h2> Possible errors and hazards associated with the use of the adhesive </h2>
                    <ul>
                        <li> Insufficient drying of the wound surface </li>
                        <li> Incomplete preliminary hemostasis of the wound. </li>
                        <li> Applying excess glue </li>
                        <li> Use of glue for large wound sizes without reinforcement by sutures or autografts </li>
                        <li> Violation of the ultrasonic effect mode when combining the glue connection with scoring. </li>
                        <li> The glue can not be applied to the mucosa of the vocal cords, the apparatus of the inner ear. </li>
                        <li> "Sulfacrylate" does not adhere fatty tissue. </li>
                    </ul>
                    <h3>
                        Storage conditions </h3>
                    <p style={{textAlign: "left"}}>
                        Medical glue "Sulfacrylate" should be stored in a dark place at a temperature of not more than + 15 ° C. Shelf
                        life - 1 year from the date of manufacture.
                    </p>
                </div>
            </div>
        </div>
    )
}
    
export default Instruction;