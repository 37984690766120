import React, { Component } from 'react';
import { Galleria } from 'primereact/galleria';

export class GalleriaApp extends Component {

    constructor(props) {
        super(props);

        this.state = {
            images: null
        };

        this.galleriaService =[
            {
                src:"1.png",
                alt:"First"
            }
            ,
            {
                src:"2.png",
                alt:"Second"
            }
            ,
            {
                src:"3.png",
                alt:"Third"
            }
        ];
        this.itemTemplate = this.itemTemplate.bind(this);
        this.thumbnailTemplate = this.thumbnailTemplate.bind(this);

        this.responsiveOptions = [
            {
                breakpoint: '1024px',
                numVisible: 5
            },
            {
                breakpoint: '768px',
                numVisible: 3
            },
            {
                breakpoint: '560px',
                numVisible: 1
            }
        ];
    }

    componentDidMount() {
        this.setState({ images: this.galleriaService });
    }

    itemTemplate(item) {
        return <img src={"images/"+item.src} alt={item.alt} style={{ width: '100%', display: 'block' }} />;
    }

    thumbnailTemplate(item) {
        return <img src={"images/tem"+item.src} alt={item.alt} style={{ display: 'block' }} />;
    }

    render() {
        return (
            <div>
                <div className="card">
                    <Galleria  value={this.state.images} responsiveOptions={this.responsiveOptions} numVisible={3} style={{ maxWidth: '640px' }}
                        item={this.itemTemplate} thumbnail={this.thumbnailTemplate} circular autoPlay transitionInterval={2000} />
                </div>
            </div>
        );
    }
}