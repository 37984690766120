
import React from 'react';
import {
    useParams,
    useHistory
  } from "react-router-dom";
import api from '../api';
import CardVertical from '../component/cardsvertical';
import './style.css'
import { BreadCrumb } from 'primereact/breadcrumb';
import parse from 'html-react-parser';

const Data = (props) => {
    const chnage = (title)=>{
        title = title.split("_");
        title = title.join(' ');
        return title
    }
    const home = { icon: 'pi pi-home', url: '/' }
    const {id} = useParams();
    const history = useHistory();
    const [data,setData] = React.useState(null);
    const getData = ()=>{
        api.post("data.php",{id:id}).then(e=>setData(e.data)).catch(e=>history.push("/"));
    }
    const items = [
        { label: chnage(id) },
    ];
    React.useEffect(getData,[id])
    console.log(data)
    return(
        <div className={"containerss"}>
            <div className={"lefts"}>
                <CardVertical/>
            </div>
            <div className={"rights"}>
                <BreadCrumb model={items} home={home}/>
                {
                    data !=null &&(
                        <div className ='content'>
                        <h1>
                            {
                                chnage(data.texts)
                            }
                        </h1>
                        {
                            parse(data.descr)
                        }
                        </div>
                    )
                }
            </div>
        </div>
    )
}
    
export default Data;