
import React from 'react';
import { Card } from 'primereact/card';

import "./cards2.css"
import api from '../api';

import {
    Link,
    useParams
} from 'react-router-dom';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';

const CardVertical = (props) => {
    const {id} = useParams();
	const [data,setData] = React.useState([]);
    const [size, setSize] = React.useState([0, 0]);
    const [visible,setVisible] = React.useState(false)
    const getData = ()=>{
        api.post("data.php",{get:"get"}).then(e=>setData(e.data)).catch(e=>console.log(e));
    }
    React.useLayoutEffect(() => {
        function updateSize() {
          setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
      }, []);
    React.useEffect(getData,[])
    if(size[0]>660)
    {
        return (<div className={"containers"}>
            {
                data.map((v,i)=>{
                    let title = v.texts
                    title = title.split("_");
                    title = title.join(' ');
                    const classes = id==v.texts ? "active" : ""
                    return <Link key={i} className={"url"} to={"/data/"+v.texts}>
                            <Card className={"cards"}>
                                <img className={"image "+classes} alt={title} src={"/uses/"+v.id+".png"} />
                                <h3 className={"title "+classes}>{title}</h3>
                            </Card>
                    </Link>
                })
            }
            </div>)
    }
    return (
        <>
                <Button icon="pi pi-th-large" onClick={() => setVisible(true)} className="p-mr-2 position" />
        <Sidebar visible={visible} onHide={() => setVisible(false)}>
            {
                data.map((v,i)=>{
                    let title = v.texts
                    title = title.split("_");
                    title = title.join(' ');
                    const classes = id==v.texts ? "active" : ""
                    return <Link key={i} className={"url"} to={"/data/"+v.texts}>
                            <Card className={"cards"}>
                                <img className={"image "+classes} alt={title} src={"/uses/"+v.id+".png"} />
                                <h3 className={"title "+classes}>{title}</h3>
                            </Card>
                    </Link>
                })
            }
        </Sidebar>
        </>
    );
}
    
export default CardVertical;