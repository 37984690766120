
import React from 'react';
import CardVertical from '../component/cardsvertical';
import './style.css'
import { BreadCrumb } from 'primereact/breadcrumb';

const Company = (props) => {
    const home = { icon: 'pi pi-home', url: '/' }
    const items = [
        { label: "About Company" },
    ];
    
    return(
        <div className={"containerss"}>
            <div className={"lefts"}>
                <CardVertical/>
            </div>
            <div className={"rights"}>
                <BreadCrumb model={items} home={home}/>
                <div className ='content'>
                    <h1>About Company</h1>
                </div>
            </div>
        </div>
    )
}
    
export default Company;