
import React from 'react';
import "primereact/resources/themes/saga-blue/theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"
import "./App.css"
import Navbar from './component/navbar';
import { GalleriaApp } from './component/gallery';
import Cards from './component/cards';
import Footer from './component/footer';

import {
    BrowserRouter as Router,
    Route,
    Link,
    Switch
} from 'react-router-dom';
import Data from './screen/medical';
import ScrollToTop from './component/ScrollToTop';
import Company from './screen/company';
import Instruction from './screen/Instruction';
import Indications from './screen/uses';
const Home = ()=>{
    return(
        <>
        <GalleriaApp/>
        <div className="mt-5"/>
        <Cards/>
        </>
    )
}
const App = () => {
    return(
        <Router>
            <ScrollToTop/>
            <Navbar/>
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route exact path="/data/:id" component={Data}/>
                <Route exact path="/company" component={Company}/>
                <Route exact path="/instruction" component={Instruction}/>
                <Route exact path="/indications" component={Indications}/>
                
            </Switch>
            <Footer/>
        </Router>

    )
}
    
export default App;